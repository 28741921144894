+function (window, $) {

	var main = {
		init: function () {
			var self = this;

			this.$content = $('#tmpl-sections').html();
			this.$loading = $('.loading');

			var assets = [
				"/assets/img/logo.png",
				"/assets/img/logo2.png",
				"/assets/img/logosp.png",
				"/assets/img/left_wt.png",
				"/assets/img/right_wt.png",
				"/assets/img/pattern.jpg"
			];

			this.loadAssets(assets, function () {
					self.setContent();
					self.initFullPage();
					setTimeout(function () {
						self.hideLoading();
					}, 500);
				}
			, function (err) {
				console.log(err);
			});
		},

		setContent: function () {
			$('body').prepend(this.$content);
		},

		hideLoading: function () {
			var self = this;

			$('p', this.$loading).removeClass('in');

			this.$loading.addClass('out');

			setTimeout(function () {
				self.$loading.remove();
			}, 500);
		},

		initFullPage: function () {
			var self = this,
				$nav = $('.nav-mn a');

			$('#fullpage').fullpage({
				anchors:['home', 'info', 'about'],
			    afterLoad: function(anchorLink, index){
					$nav.removeClass('active');
					$nav.eq(index-1).addClass('active');
			    }
			});
		},

		loadAssets: function(assets, cbSuccess, cbError) {
            var proms = [],
                p = null;

            assets.forEach(function (asset) {
                p = new Promise(function(resolve, reject) {
                    var img = new Image();

                    img.onload = function(){
                        resolve();
                    };

                    img.onerror = function(){
                        reject();
                    };

                    img.src = asset;
                });

                proms.push(p);
            });

            Promise.all(proms)
                .then(cbSuccess)
                .catch(cbError);
        },
	};

	$(document).ready(function() {
		main.init();
	});
}(window, jQuery);
